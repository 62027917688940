body{margin: 0;padding: 0;}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mapboxgl-ctrl-group {
  border-radius: 3rem !important;
  /* background: #669b1e !important; */
  background-color: rgba(102, 155, 30, 0.8) !important;
  /* padding: 0.5rem; */
  /* border: 0.2rem solid white; */
  border:0.2rem solid rgba(255, 255, 255, 0.5);

  position: relative;
  top:1rem;
  right:1.6rem;
}

.mapboxgl-ctrl-group button {
  border: 0.2rem solid white !important;
  border-radius: 3rem !important;
  width:50px !important;
  height:50px !important;
  cursor: pointer !important;
  display: block;
  padding: 0;
  border: 0;
  margin: -3px;
  box-sizing: border-box;
  background: #669b1e !important;
  /* background-color: transparent; */
  overflow: hidden;
}
/* .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon{color:#fff !important;} */
.mapboxgl-ctrl-group button:not(:last-child){
  margin-bottom: 0.8rem;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon, .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: unset !important;
  width: 100%;
  height: 100%;
  text-align: center;
}
.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon{
  /* filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%); */
  filter: brightness(0) invert(1);
}
.mapboxgl-ctrl-icon{
  position:  relative;
}

.mapboxgl-ctrl-icon:before {
  height: 100%;
  font-size: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon:before{
  content: "+";
  font-weight: 500;
  color: white;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon:before{
  content: "-";
  font-weight: 500;
  color: white;
  margin-top: -4px;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon:before {
}
/* .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url();
} */

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon:before{
    background-image: url(assets/icons/information_icon_white.png);
    background-size: 10px 20px;
    display: inline-block;
    width: 10px;
    height: 20px;
    content:"";
    margin-top: 11px;

}

/* @media screen and (max-width: 767px){

} */
/* .mapboxgl-ctrl-bottom-right {
  /* right: 4rem;
  bottom: 6rem;
} */
.mapboxgl-ctrl-bottom-right {
  bottom:2rem !important;
  z-index: 20;
}
/* @media screen and (max-width: 767px) {
  .mapboxgl-ctrl-bottom-right {
    right: 0rem;
    bottom: 2rem;
  }
} */

.mapboxgl-ctrl-bottom-left{
  left:3rem !important;
  /* bottom:2rem !important; */
}
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon:before{
  /* content: "?"; */
  /* font-size: 2.6rem; */
  /* font-weight: 600; */
  /* color: white; */
}

.toolbarclass{display: none;}
.editorClass{color:#fff;}

.mapboxgl-canvas{
  width: 100% !important;
  height: 100% !important;
}
#mapContainer{
  /* height: 100% !important; */
}

.showIcon{
  visibility: visible;
}
.hideIcon{
  visibility: hidden;
}

.showFeaturedName{
    /*visibility: visible;*/
    display: block;
    /*width: 14rem !important;*/
}
.hideFeaturedName{
    /*visibility: hidden;*/
    display: none;
}


#info {
  display: table;
  position: relative;
  margin: 0px auto;
  word-wrap: anywhere;
  white-space: pre-wrap;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  color: #222;
  background: #fff;
  }

@media screen and (max-width: 767px) {
    .loader {
      left :40%;
    }
}
.button {
  background: 0 0;
  color: inherit;
  font: inherit;
  padding: 0;
  width: inherit;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 0;
}
/*
.loader {
  height: 100px;
  width: 20%;
  position: absolute;
  padding: 1em;
  left: 45%;
  top: 45%;
  margin: 0 auto 1em;
  z-index: 9999;
}

#loader-1 path{
  fill:blue;
}
*/

.mapboxgl-marker.mapboxgl-marker-anchor-center > img {
    max-width: 70px;
    max-height: 70px;
    pointer-events: auto !important
}
.mapboxgl-marker.mapboxgl-marker-anchor-center.largerIcon {
  max-width: 200px;
}
.mapboxgl-marker.mapboxgl-marker-anchor-center.largerIcon > div {
  font-size: 1.25rem !important;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center.largestIcon {
  max-width: 200px;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center.largestIcon > div {
}

.mapboxgl-marker.mapboxgl-marker-anchor-center.normal-destination {
  max-width: 100px;
  pointer-events: none !important;
}
.mapboxgl-marker.mapboxgl-marker-anchor-center.normal-destination > div {
    display: none;
    pointer-events: auto !important
}
.mapboxgl-marker.mapboxgl-marker-anchor-center.normal-destination.showText > div {
    display: block;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {

}


.offsite-trails-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50px;
    z-index: 10;
}

.offsite-trails-left {
    position: absolute;
    top: 50%;
    margin-top: -100px;
    transform: rotate(90deg);
    -webkit-transform-origin: 50% 51%;
    left: 0px;
    z-index: 10;
}

.offsite-trails-top {
    position: absolute;
    top: 80px;
    left: 50%;
    margin-left: -50px;
    z-index: 10;
}

.offsite-trails-right {
    position: absolute;
    top: 50%;
    transform: rotate(-90deg);
    right: 0;
    margin-right: -40px;
    z-index: 10;
}

.offsite-trail {
    background: rgb(113, 115, 117);
    padding: 3px 20px 3px 20px;
    color: #fff;
    margin: 4px 0;
    cursor: pointer;
}

.hideOffSiteTrail {
    display: none;
}

.showOffSiteTrail {
    display: unset;
}

div#northArrow {
  z-index: 10;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}
#northArrow img {
  max-width: 70px;
  max-height: 70px;
}

.iconClass {
  width: 25px;
  margin-right: 8px;
  cursor: default;
  background: #fff;
  padding: 5px;
  border-radius: 30%;
}

.name-location-top .showFeaturedName {
  margin-top: -2.5rem !important;
}
/* 2532x1170 pixels at 460ppi */
@media only screen
and (device-width: 390px)
and (device-height: 844px)
and (-webkit-device-pixel-ratio: 3) {
    .offsite-trails-bottom {
        position: absolute;
        bottom: 0;
        left: 32%;
        margin-left: -50px;
        z-index: 1;
    }

    .offsite-trails-left {
        position: absolute;
        top: 60%;
        transform: rotate(90deg);
        -webkit-transform-origin: 50% 51%;
        left: -70px;
    }

    .offsite-trails-top {
        position: absolute;
        top: 80px;
        left: 31%;
        margin-left: -50px;
        z-index: 10;
    }

    .offsite-trails-right {
        position: absolute;
        top: 50%;
        transform: rotate(-90deg);
        right: 0;
        margin-right: -40px;
        z-index: 1;
    }

    .offsite-trail {
        background: rgb(113, 115, 117);
        padding: 3px 10px 3px 30px;
        color: #fff;
        margin: 4px 0;
    }

    .showOffSiteTrail{
        top: 10%;
    }

}
