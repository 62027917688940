body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Klinic Slab Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Klinic Slab Bold'), url('assets/fonts/KlinicSlabBold.otf') format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light'), url('assets/fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold'), url('assets/fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('assets/fonts/MyriadPro-Bold.otf') format('opentype');
}
